html { 
  background: url('/background.jpeg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  scroll-behavior: smooth;
}

@media (min-width: 800px) {
  .navigation {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    font-weight: 900;
    background: white;
    border-color: rgb(27, 104, 137);
    border-width: 3px;
    width: 100vw;
    border-bottom-style: solid;
    z-index: 2;
    box-shadow: 0px 0px 24px 1px rgba(87, 87, 87, 0.3);
  }
}

.mobile-nav {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  font-weight: 900;
  background: white;
  border-color: rgb(27, 104, 137);
  border-width: 3px;
  width: 100vw;
  border-bottom-style: solid;
  z-index: 2;
  /* max-height: 100vh; */
  box-shadow: 0px 0px 24px 1px rgba(87, 87, 87, 0.3);
}

.big-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba(27, 104, 137, 0.4);
  height: 96vh;
  top: 4vh;
  justify-content: space-around;
}

.big-nav-ele {
  text-align: center;
  color: white;
  font-size: 5vh;
  font-family: "Optima", sans-serif;
  transition: transform  200ms ease-in-out;
}

.hamburger {
  position: absolute;
  content: "";
  right: 5vw;
  top: 0.75vw;
  width: 2em;
  height: 0.30em;
  background: rgb(27, 104, 137);
  border-radius: 20px;
  box-shadow: 
    0 0.5em 0 0 rgb(27, 104, 137),
    0 1em 0 0 rgb(27, 104, 137);
  z-index: 2;
}

.hamburger:after {
  content:'';
  position:absolute;
  top:-20px; bottom:-20px; 
  left:-20px; right:-20px; 
}

.dropbutton:hover {
  color: white;
  background-color: rgb(27, 104, 137);
}

.dropdown:hover .drop-content {
  display: flex;
  flex-direction: column;
}

.company {
  font-size: initial;
  background-color: #ffffff;
  padding: 1vw;
  cursor: default;
  color: rgb(27, 104, 137);
  margin: 0;
  margin-right: auto;
}

.drop-content {
  font-size: initial;
  position: absolute;
  display: none;
  z-index: 2;
  border-color: rgb(27, 104, 137);
  border-width: 3px;
  background: white;
  margin-left: -.1vw;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
}

.drop-ele {
  padding: 1vw;
  margin: 0;
  background-color: white;
  transition: transform  200ms ease-in-out;
}

.drop-ele:hover {
  color: white;
  background: rgb(27, 104, 137);
}


/* .drop-ele:active {
  transform: scale(0.8);
} */

.dropbutton {
  font-size: initial;
  padding: 1vw;
  background-color: #ffffff;
  font-weight: 900;
  margin: 0;
  transition: transform  150ms ease-in-out;
}

/* .dropbutton:active {
  transform: scale(0.8);
} */

.nav-ele {
  font-size: initial;
  padding: 1vw;
  margin: 0;
  transition: transform  150ms ease-in-out;
}

.nav-ele-drop {
  background-color: #ffffff;
  transition: transform  150ms ease-in-out;
}

.nav-ele:hover{
  cursor: pointer;
  color: white;
  background: rgb(27, 104, 137);
}

.home {
  position: absolute;
  top: 100vh;
  background-color: white;
  background-image: url('/lab.jpg') ;
  background-size: 100vw 100vh;
  width: 100vw;
}

.first {
  color: rgb(27, 104, 137);
}

@media (max-width: 800px) {
  .team {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 10vh;
  }
}

@media (min-width: 800px) {
  .team {
    position: relative;
    display: grid;
    place-items: center;
    margin: 0 auto;
    top: 20vh;
  }
}

.row {
  margin-right: 7.5vw;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
}

.homepage {
  max-width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 800px) {
  .teamtext {
    font-family: "Optima", sans-serif;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    color: white;
    max-width: 80vw;
    font-size: 10vh;
    cursor: default;
    line-height: 12vh;
    letter-spacing: 0.1vw;
  }
}

@media (max-width: 800px) {
    .teamtext {
      font-family: "Optima", sans-serif;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5vh;
      color: white;
      font-size: 8vh;
      cursor: default;
      line-height: 12vh;
      letter-spacing: 0.1vw;
  }
}


.linker {
  text-decoration: underline;
  text-decoration-color: rgb(27, 104, 137);
}

.app {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-x: hidden;
  right: 0;
}

.mainhome {
  height: 85vh;
}

.page-img {
  height: auto;
  max-width: 50vw;
  max-height: 80vh;
  align-self: flex-end;
}

@media (max-width: 600px) {
  .tagline-2 {
    font-family: "Optima", sans-serif;
    color: rgb(27, 104, 137);
    font-size: 3.1vh;
    cursor: default;
    width: 40vw;
    max-width: 40vw;
    margin: auto;
  }
}

@media (min-width: 600px) {
  .tagline-2 {
    font-family: "Optima", sans-serif;
    color: rgb(27, 104, 137);
    font-size: 4vw;
    cursor: default;
    width: 40vw;
    max-width: 40vw;
    margin: auto;
  }
}

.outline {
  cursor: default;
  color: transparent;
  -webkit-text-stroke-width: 0.75px;
  -webkit-text-stroke-color: rgb(27, 104, 137);
}

.chevron {
  color: rgb(27, 104, 137);
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  display: inline-block;
  height: 1.80em;
  transform: rotate(135deg);
  width: 1.80em;
  padding: 5px;
  z-index: 1;
}

.center-chevron {
  margin-top: 1vh;
  background:white;
  position: relative;
  display: grid;
  place-items: center;
}

.big-word2 {
  font-family: "Optima", sans-serif;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  color: black;
  max-width: 70vw;
  font-size: 5vh;
  font-weight: 1;
  cursor: default;
  line-height: 8vh;
  background: white;
  padding: 2vh;
}

.tagline {
  font-family: "Optima", sans-serif;
  color: white;
  font-size: 11vh;
  cursor: default;
  margin: 0;
}

@media (max-width: 600px) {
  .tagline-box {
    position: relative;
    display: grid;
    place-items: center;
    margin: 0 auto;
    top: 10vh;
  }
}

@media (min-width: 600px) {
  .tagline-box {
    position: relative;
    display: grid;
    place-items: center;
    margin: 0 auto;
    top: 20vh;
  }
}

.headshot {
  position: relative;
  max-width: 20vw;
  height: auto;
  z-index: -1;
}

.biotext {
  font-family: "Optima", sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  color: black;
  max-width: 60vw;
  font-weight: 1;
  cursor: default;
  background: white;
  padding: 3vh;
  letter-spacing: 0.05vw;
  z-index: -1;
}

.biotitle {
  font-size: 4vh;
  color: rgb(27, 104, 137);
}

.biopage {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 85vw;
  margin-top: 20vh;
  margin-bottom: 10vh;
}


@media (min-width: 1400px) {
  .contitle {
    font-family: "Optima", sans-serif;
    position: absolute;
    left: 15vw;
    top: 10vh;
    width: 50vw;
    color: white;
    font-size: 6vw;
    cursor: default;
  }

  .context {
    position: absolute;
    font-family: "Optima", sans-serif;
    color: white;
    max-width: 60vw;
    font-weight: 1;
    top: 35vh;
    left: 15vw;
    font-size: 3vw;
    letter-spacing: 0.05vw;
    line-height: 4vw;
    z-index: -1;
  }
}

@media (max-width: 1400px) {
  .contitle {
    font-family: "Optima", sans-serif;
    position: absolute;
    left: 15vw;
    top: 10vh;
    width: 50vw;
    color: white;
    font-size: 8vh;
    cursor: default;
  }

  .context {
    position: absolute;
    font-family: "Optima", sans-serif;
    color: white;
    max-width: 60vw;
    font-weight: 1;
    top: 35vh;
    left: 15vw;
    font-size: 6vh;
    letter-spacing: 0.1vw;
    line-height: 7vh;
    z-index: -1;
  }
}

.portfolio {
  display: inline-flex;
  background: whitesmoke;
  max-width: 90vw;
  min-width: 40vw;
  padding: 0.5vw;
  transition: transform  200ms ease-in-out;
  margin-bottom: 2vh;
  border-radius: 5px;
}

.portfolio:hover {
  transform: scale(1.02);
  -webkit-font-smoothing: subpixel-antialiased;
}

.blurb {
  font-family: "Optima", sans-serif;
  font-size: 2.2vh;
}

.portfolio-group {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 60ch);
  row-gap: 1vh;
  column-gap: 3vw;
  top: 10vh;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
}

.portfolio-company {
  color: rgb(27, 104, 137);
  padding: 1vw;
  margin: 0.5vh;
  z-index: 1;
}

.news-case {
  position: relative;
  width: 85vw;
  margin: auto;
  margin-bottom: 5vh;
  margin-top: 10vh;
}

.newsreel {
  display: grid;
  justify-items: stretch;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, 30ch);
  grid-auto-rows: minmax(min-content, max-content);
  gap: 2rem;
  position: relative;
  align-items: stretch;
  justify-content: space-evenly;
  align-content: space-evenly;
}

.newsitem {
  background: whitesmoke;
  padding: 1vw;
  border-radius: 5px;
  cursor: pointer;
  transition: transform  125ms ease-in-out;
}

.newsitem:hover {
  font-weight: 525;
  transform: scale(1.02);
  -webkit-font-smoothing: subpixel-antialiased;
}

.date {
  margin-bottom: 0.5vw;
  color: rgb(27, 104, 137);
}

.body {
  font-family: "Optima", sans-serif;
  font-size: 2.5vh;
}

.whitespace {
  height: 6vh;
  background: white;
}